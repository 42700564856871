export const RotatingCard = {
  data() {
    return {
      rotating: false,
      editMode: false
    };
  },
  methods: {
    rotate() {
      this.rotating = true;
      this.editMode = true;

      setTimeout(() => {
        this.rotating = false;
      }, 500);
    },
    rotateBack() {
      this.rotating = true;
      this.editMode = false;

      setTimeout(() => {
        this.rotating = false;
      }, 500);
    }
  }
};
