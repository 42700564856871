<template>
  <section class="w-form-card" :class="{ 'w-form-card--rotate': rotating }">
    <h3 class="w-form-card__title">
      {{ $t("label.choose_service") }}
    </h3>

    <section class="w-form-card__body height-90">
      <!-- Main Services -->
      <b-form-group
        v-for="(service, index) in GlobalServices"
        :key="index"
        class="w-check-group w-check-group--main"
      >
        <b-form-checkbox
          v-model="selectedMainServices"
          :value="service.id"
          button
        >
          {{ service.name }}
        </b-form-checkbox>

        <!-- Sub Services -->
        <section v-if="selectedMainServices.includes(service.id)">
          <!-- <i class="glyph-icon flaticon-next"></i> -->
          <!-- <span v-if="locale == 'en'"> &#x21AA; </span> -->
          <!-- <span v-else> &#x21A9; </span> -->
          <b-form-group
            v-for="(subService, subIndex) in service.sub_services"
            :key="subIndex"
            class="w-check-group w-check-group--sub d-inline-block"
          >
            <b-form-checkbox
              v-model="selectedSubServices"
              :value="subService.id"
              button
              class="service__checkbox"
            >
              {{ subService.name }}
            </b-form-checkbox>
          </b-form-group>
        </section>
        <!-- Sub Services -->
      </b-form-group>
      <!-- Main Services -->

      <div
        v-if="GlobalServices.length === 0"
        class="text-center mb-3 d-flex justify-content-center"
      >
        <b-spinner variant="info"></b-spinner>
      </div>
    </section>

    <section class="w-form-card__actions">
      <b-button
        class="button button--filled"
        :disabled="selectedSubServices.length < 1"
        @click="save"
        >{{ $t("button.next") }}</b-button
      >
    </section>

    <!-- Disability Layer -->
    <section v-if="!isActive" class="w-form-card__overlay--disabled"></section>
    <!-- Disability Layer -->

    <section
      class="w-form-card__overlay"
      :class="{ 'w-form-card__overlay--show': editMode }"
    >
      <section class="w-form-card__overlay__body">
        <h4 class="w-form-card__overlay__header">
          {{ $t("label.service") }} :
        </h4>
        <h5
          v-for="(chosen, chosIndex) in ChosenServices"
          :key="chosIndex"
          class="w-form-card__overlay__body"
        >
          <span> {{ chosen.name }} </span>
          <span
            v-for="(subChose, subChosIndex) in chosen.ChosenSubs"
            :key="subChosIndex"
            class="l-h-2"
          >
            <br />
            <i class="icon-submit mx-2"></i>
            {{ subChose.name }}
          </span>
        </h5>
      </section>
      <b-button
        v-if="!disableEdits"
        class="button button--outline-white w-form-card__overlay__button"
        @click="edit"
        >{{ $t("button.edit") }}</b-button
      >
    </section>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { RotatingCard } from "@/constants/Mixins/RotatingCard";
import Cookies from "js-cookie";

export default {
  name: "ChooseService",
  mixins: [RotatingCard],

  props: {
    isActive: {
      type: Boolean,
      required: true
    },
    disableEdits: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters(["GlobalServices"])
  },
  data() {
    return {
      selectedMainServices: [],
      selectedSubServices: [],
      ChosenServices: [],
      locale: Cookies.get("language") // has to
    };
  },
  methods: {
    save() {
      this.ChosenServices = this.GlobalServices.filter(service => {
        return this.selectedMainServices.includes(service.id);
      });

      this.ChosenServices.forEach(service => {
        service.ChosenSubs = service.sub_services.filter(sub => {
          return this.selectedSubServices.includes(sub.id);
        });
      });

      this.$emit("choosedServices", this.ChosenServices); // handle Submit
      this.rotate();
    },
    edit() {
      this.$emit("choosedServices", []); // handle reset Submit
      this.rotateBack();
    }
  }
};
</script>
